type PriceState = {
  asNumber: number
  asString: string
}

const formatter = Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' })

export async function usePrice () {
  const ausserbetriebsetzung = useState<PriceState>('ab-price')
  const umschreibung = useState<PriceState>('ug-price')

  const { backendUrl, source } = useRuntimeConfig().public

  await callOnce(async () => {
    const prices = await $fetch(`${backendUrl}/prices`, { query: { source }}) as { ausserbetriebsetzung: number, umschreibung: number }
    
    ausserbetriebsetzung.value = {
      asNumber: prices.ausserbetriebsetzung,
      asString: formatter.format(prices.ausserbetriebsetzung)
    }

    umschreibung.value = {
      asNumber: prices.umschreibung,
      asString: formatter.format(prices.umschreibung)
    }
  })

  return {
    ausserbetriebsetzung,
    umschreibung
  }
}
